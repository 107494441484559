<template>
  <!-- v-if needs to be here, bc filters and column settings depend on the landingpage types  -->
  <div
    v-if="landingpages"
    class="full-size-container h-full"
  >
    <LeadList
      :leads="preparedLeads"
      :leads-by-status="leadsByStatus"
      :users="usersForSelect()"
      :loading="$apollo.queries.leads.loading"
      :leads-by-status-loading="leadsByStatusLoading"
      :server-items-length="leads.total"
      :items-per-page="itemsPerPage"
      :refetch-leads="refetchLeads"
      :disable-filters="isOnlyAssignedToMe"
      :disable-lead-import="!canImportLeads"
      :landingpage-types="landingpages"
      :select-items="selectLead"
      :is-lead-import-table="canImportLeads"
      :selected-lead-ids="selectedLeadIds"
      :parent-trigger-select-all-items="triggerSelectAllItems"
      :trigger-reset-delete-leads="triggerResetDeleteLeads"
      :trigger-reset-assign-leads="triggerResetAssignLeads"
      :select-all-leads-state="selectAllLeadsState"
      :is-deleting-leads="isDeletingLeads"
      :is-assigning-leads="isAssigningLeads"
      :sort-leads-by-position="sortLeadsByPosition"
      @allItemsSelected="allItemsSelected"
      @updateFetchIdsParameters="updateFetchIdsParameters"
      @invitationSent="invitationSent"
      @deleteLeads="deleteLeads"
      @assignLeads="assignLeads"
      @updateLeadsPosition="updateLeadsPosition"
      @getLeadsByStatus="getLeadsByStatus"
      @triggeredSelectAllItems="triggeredSelectAllItems"
      @triggeredResetDeleteLeads="triggeredResetDeleteLeads"
      @triggeredResetAssignLeads="triggeredResetAssignLeads"
    />
  </div>
</template>

<script>
import LEADS from './queries/Leads.gql'
import ASSIGN_LEADS from './queries/AssignLeads.gql'
import DELETE_LEADS from '@/modules/leads/queries/DeleteLeads.gql'
import LANDINGPAGES from './Landingpages.gql'
import ACQUISITION_BOOSTER_LEAD_IDS from './queries/AcquisitionBoosterLeadIds.gql'
import LEAD_IDS from './queries/LeadIds.gql'
import bus, { eventNames } from '@/lib/eventBus'
import { InvitationContactFilter } from './enums/Invitation'
import featureMixin from '@/mixins/feature'
import { DeleteLeadsContactFilter } from './enums/DeleteLeads'
import UPDATE_LEADS_POSITION from '@/modules/leads/Lead/queries/UpdateLeadsPosition.gql'
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import trackingEvents from '@/lib/trackingEvents'
import { Status } from '@/modules/leads/enums/Status'
import leadStatus from '@/lib/leadStatus'
import USERS from '@/modules/leads/queries/Users.gql'
import { sortByString } from '@/lib/sort-functions'

export default {
  components: {
    LeadList: () => import('./LeadList')
  },
  mixins: [featureMixin],
  props: {
    isOnlyAssignedToMe: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      leadsByStatusLoading: false,
      leadsByStatus: [],
      leads: {
        total: 0,
        entries: []
      },
      sortBy: 'lastConversionAt',
      sortDesc: true,
      page: 1,
      itemsPerPage: 20,
      search: '',
      filters: {
        currentStatus: null,
        assignedUser: null,
        valuations: false,
        returnCalls: false,
        lifeAnnuities: false,
        partialSales: false,
        leadOriginConversion: true,
        leadOriginImport: true
      },
      landingpageSlugFilter: '',

      selectAllLeadsState: 'idle',
      selectedLeadIds: [],

      isDeletingLeads: false,
      isAssigningLeads: false,

      company: {},

      triggerSelectAllItems: false,
      triggerResetAssignLeads: false,
      triggerResetDeleteLeads: false
    }
  },
  apollo: {
    users: {
      query: USERS,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    leads: {
      query: LEADS,
      variables () {
        return {
          landingpageSlug: this.landingpageSlugFilter,
          input: {
            companyId: this.$auth.user.companyId,
            onlyAssignedToMe: this.isOnlyAssignedToMe,
            sortBy: this.sortBy,
            view: 'all',
            sortDesc: this.sortDesc,
            page: this.page,
            itemsPerPage: this.itemsPerPage,
            search: this.search,
            filters: Object.assign({}, this.filters)
          }
        }
      },
      result () {
        this.triggerSelectAllItems = false
      }
    },
    company: {
      query: LANDINGPAGES,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  },
  computed: {
    landingpages () {
      if (!this.company) return
      return this.company.landingpages
    },

    preparedLeads () {
      if (!this.leads) return
      return this.leads.entries
    },

    canImportLeads () {
      return this.isFeatureActive(this.featureNames.LEAD_IMPORT)
    }
  },
  methods: {
    triggeredResetDeleteLeads () {
      this.triggerResetDeleteLeads = false
    },
    triggeredResetAssignLeads () {
      this.triggerResetAssignLeads = false
    },
    triggeredSelectAllItems () {
      this.triggerSelectAllItems = false
    },
    refetchLeads ({ sortBy = this.sortBy, sortDesc = this.sortDesc, page = this.page, itemsPerPage = this.itemsPerPage, search = this.search, filters = this.filters, landingpageSlugFilter = this.landingpageSlugFilter }) {
      // cancel pending call
      clearTimeout(this._timerId)

      this.page = page
      this.sortBy = sortBy
      this.sortDesc = sortDesc
      this.itemsPerPage = itemsPerPage
      this.filters = filters
      this.landingpageSlugFilter = landingpageSlugFilter

      // delay new call 1000ms
      this._timerId = setTimeout(() => {
        this.search = search
        this.$apollo.queries.leads.refetch()
        const statuses = this.filters.currentStatus
          ? this.filters.currentStatus === Status.NEW
            ? [Status.NEW, Status.CREATED]
            : [this.filters.currentStatus]
          : [
            Status.NEW,
            Status.CREATED,
            Status.FOLLOW_UP,
            Status.REACHED,
            Status.UNREACHED,
            Status.APPOINTMENT_ON_SITE,
            Status.ORDER_RECEIVED,
            Status.NO_FURTHER_CONTACT,
            Status.UNREACHED_AT_ALL
          ]
        this.getLeadsByStatus(statuses, 1)
      }, 1000)
    },

    async invitationSent () {
      this.selectedLeadIds = []
      this.landingpageSlugFilter = ''
      await this.refetchLeads({})
    },

    assignUserColors (users) {
      users.forEach(section => {
        section.leads.forEach(lead => {
          const assignedUser = lead.assignedUser
          if (assignedUser) {
            const userId = assignedUser.id

            // Assign a consistent color based on user ID
            assignedUser.color = this.getRandomColor(userId)
          }
        })
      })

      return users
    },

    getRandomColor (id) {
      let hash = 0
      for (let i = 0; i < id.length; i++) {
        hash = id.charCodeAt(i) + ((hash << 5) - hash)
      }
      let color = '#'
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF
        color += ('00' + value.toString(16)).substr(-2)
      }
      return color
    },

    async getLeadsByStatus (statuses, page) {
      const leadsByStatus = []
      for (const status of statuses) {
        this.leadsByStatusLoading = true
        try {
          const { data } = await this.$apollo.mutate({
            mutation: LEADS,
            variables: {
              landingpageSlug: this.landingpageSlugFilter,
              input: {
                companyId: this.$auth.user.companyId,
                onlyAssignedToMe: this.isOnlyAssignedToMe,
                sortBy: this.sortBy,
                view: 'platform',
                sortDesc: this.sortDesc,
                page: page,
                itemsPerPage: this.itemsPerPage,
                search: this.search,
                filters: { ...this.filters, currentStatus: status }
              }
            }
          })

          if (!data) { return }

          if (status === Status.CREATED) {
            const newLeads = leadsByStatus.find(lead => lead.status === Status.NEW)

            if (newLeads) {
              newLeads.leads = this.sortLeadsByPosition([...newLeads.leads, ...data.leads.entries])
              newLeads.total += data.leads.total
            }
          } else {
            const sortedLeads = this.sortLeadsByPosition(data.leads.entries)
            const totalLeads = data.leads.total
            const title = leadStatus[status]

            leadsByStatus.push({
              title,
              leads: sortedLeads,
              total: totalLeads,
              status,
              page
            })
            this.leadsByStatus = this.assignUserColors(leadsByStatus)
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error)
        } finally {
          this.leadsByStatusLoading = false
        }
      }
    },

    usersForSelect () {
      if (!this.users || this.users.length === 0) {
        return []
      }
      return this.users.map(user => ({
        value: user.id,
        text: `${user.firstname} ${user.lastname}`,
        firstName: user.firstname,
        lastName: user.lastname,
        color: this.getRandomColor(user.id)
      }))
        .sort(sortByString('text'))
    },

    sortLeadsByPosition (leads) {
      return leads.sort((a, b) => {
        if (a.position === null) return 1
        if (b.position === null) return -1
        return a.position - b.position
      })
    },

    async deleteLeads () {
      this.isDeletingLeads = true

      if (this.selectedLeadIds.length) {
        try {
          await this.$apollo.mutate({
            mutation: DELETE_LEADS,
            variables: {
              input: {
                leadIds: this.selectedLeadIds
              }
            }
          })
          this.selectedLeadIds = []
          this.triggerResetDeleteLeads = true
          await this.refetchLeads({})
          this.$tracking.event('Leads', this.$tracking.trackingEvents.CLICKED, 'Delete Leads')
          bus.$emit(eventNames.SHOW_ANIMATION)
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.delete-leads.success') })
        } catch (error) {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.delete-leads.error') })
          // eslint-disable-next-line no-console
          console.log(error)
        } finally {
          this.isDeletingLeads = false
        }
      }
    },

    async updateLeadsPosition (leadIds) {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_LEADS_POSITION,
          variables: {
            leadIds: leadIds
          }
        })
        this.$tracking.event('Leads', this.$tracking.trackingEvents.CLICKED, 'Update Leads Position')
        /* eslint-disable-next-line no-unused-expressions */
        this.$gtm?.trackEvent({ event: trackingEvents.UPDATE_LEAD_POSITION_SUCCEEDED })
        this.triggerResetAssignLeads = true
      } catch (e) {
        showSnackbarMessage('error', this.$t('alerts.lead-position.error'))
      }
    },

    async assignLeads (userId) {
      this.isAssigningLeads = true

      if (this.selectedLeadIds.length) {
        try {
          await this.$apollo.mutate({
            mutation: ASSIGN_LEADS,
            variables: {
              input: {
                userId,
                leadIds: this.selectedLeadIds
              }
            }
          })
          this.selectedLeadIds = []
          this.triggerResetAssignLeads = true
          await this.refetchLeads({})
          this.$tracking.event('Leads', this.$tracking.trackingEvents.CLICKED, 'Assign Leads')
          bus.$emit(eventNames.SHOW_ANIMATION)
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.assign-leads.success') })
        } catch (error) {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.assign-leads.error') })
        } finally {
          this.isAssigningLeads = false
        }
      }
    },

    selectLead (items = []) {
      this.selectedLeadIds = []
      items.forEach(item => {
        if (!item.invitationBlockedReason) {
          this.selectedLeadIds.push(item.id)
        }
      })
      if (this.selectAllLeadsState === 'selected') this.selectAllLeadsState = 'idle'
    },

    async allItemsSelected ({ isSelectAll, slug }) {
      this.selectAllLeadsState = 'loading'

      if (isSelectAll) {
        try {
          if (slug === 'invitation') {
            const { data: { getAcquisitionBoosterLeadIds } } = await this.$apollo.mutate({
              mutation: ACQUISITION_BOOSTER_LEAD_IDS,
              variables: {
                input: {
                  companyId: this.$auth.user.companyId,
                  onlyAssignedToMe: this.isOnlyAssignedToMe,
                  search: this.search,
                  landingpageSlug: this.landingpageSlugFilter,
                  filters: Object.assign({}, this.filters)
                }
              }

            })
            this.selectedLeadIds = getAcquisitionBoosterLeadIds.leadIds
          } else if (['deleteLeads', 'assignLeads'].includes(slug)) {
            const { data: { leadIds } } = await this.$apollo.mutate({
              mutation: LEAD_IDS,
              variables: {
                input: {
                  companyId: this.$auth.user.companyId,
                  onlyAssignedToMe: this.isOnlyAssignedToMe,
                  search: this.search,
                  filters: Object.assign({}, this.filters)
                }
              }
            })
            this.selectedLeadIds = leadIds.leadIds
          }
          this.selectAllLeadsState = 'selected'
        } catch (err) {
          this.selectAllLeadsState = 'idle'
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.delete-leads.error-selection') })
        }
      } else {
        this.selectedLeadIds = []
        this.selectAllLeadsState = 'idle'
      }
    },

    async updateFetchIdsParameters ({ contactFilter, landingpageSlug, filters = {}, activeTool }) {
      if (activeTool === 'invitation') this.updateInvitationParameters({ contactFilter, landingpageSlug, filters })
      if (activeTool === 'deleteLeads') this.updateDeleteLeadsParameter({ contactFilter, filters })
    },

    async updateInvitationParameters ({ contactFilter, landingpageSlug, filters = {} }) {
      if (!contactFilter || !landingpageSlug) return
      this.landingpageSlugFilter = landingpageSlug
      if (contactFilter === InvitationContactFilter.CUSTOM) {
        this.selectedLeadIds = []
      } else {
        try {
          const { data: { getAcquisitionBoosterLeadIds } } = await this.$apollo.mutate({
            mutation: ACQUISITION_BOOSTER_LEAD_IDS,
            variables: {
              input: {
                companyId: this.$auth.user.companyId,
                landingpageSlug,
                filters: Object.assign({}, filters)
              }
            }
          })
          this.$tracking.event('Leads', this.$tracking.trackingEvents.CLICKED, 'Invitation Parameters')
          this.selectedLeadIds = getAcquisitionBoosterLeadIds.leadIds
        } catch (err) {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.delete-leads.error-selection') })
        }
      }
    },

    async updateDeleteLeadsParameter ({ contactFilter, filters = {} }) {
      if (!contactFilter) return
      if (contactFilter === DeleteLeadsContactFilter.CUSTOM) {
        this.selectedLeadIds = []
      } else {
        try {
          const { data: { getDeleteLeadsLeadIds } } = await this.$apollo.mutate({
            mutation: LEAD_IDS,
            variables: {
              input: {
                companyId: this.$auth.user.companyId,
                filters: Object.assign({}, filters)
              }
            }
          })
          this.$tracking.event('Leads', this.$tracking.trackingEvents.CLICKED, 'Delete Parameters')
          this.selectedLeadIds = getDeleteLeadsLeadIds.leadIds
        } catch (err) {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.delete-leads.error-selection') })
        }
      }
    }
  },
  beforeUnmount () {
    clearTimeout(this._timerId)
  }
}
</script>
